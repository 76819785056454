import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import ChangelogEntry from '../components/ChangelogEntry'
import Layout from '../components/Layout'
import SecondaryCTA from '../components/SecondaryCTA'
import Social from '../components/Social'
import { FeaturedChangelogArticles } from '../components/changelog/FeaturedChangelogEntries'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'

export default function BlogTemplate({ data }) {
  const { mdx } = data
  const { frontmatter } = mdx

  const url = 'https://www.swarmia.com' + frontmatter.slug
  const title = frontmatter.title + ' | Swarmia'
  const description = frontmatter.meta.description

  const imageData = frontmatter?.meta?.image?.w640?.original
  const image = 'https://www.swarmia.com' + imageData?.src
  const imageWidth = String(imageData?.width)
  const imageHeight = String(imageData?.height)

  return (
    <Layout justifyCenter isNew>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <Block paddingTop={48}>
        <Box maxWidth="blogContent" marginX="auto">
          <ChangelogEntry entry={mdx} standalone />
          <Box paddingTop={48}>
            <Social />
          </Box>
        </Box>
      </Block>
      <Block>
        <SecondaryCTA />
      </Block>
      <FeaturedChangelogArticles
        articles={data.relatedPosts.edges.map(
          edge => edge.node.frontmatter.slug,
        )}
        heading="More changelog updates"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!, $relatedSlugs: [String!]!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMM D, YYYY")
        slug
        title
        meta {
          description
          ogTitle
          imageAlt
          image {
            w640: childImageSharp {
              gatsbyImageData(width: 640, quality: 90)
              original {
                src
                width
                height
              }
            }
            w1000: childImageSharp {
              gatsbyImageData(width: 1000, quality: 90)
              original {
                src
                width
                height
              }
            }
          }
        }
      }
    }
    relatedPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { slug: { in: $relatedSlugs } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`
